import CarExchangeIcon from '@src/components/icons/CarExchangeIcon'
import PriceHistoryIcon from '@src/components/icons/PriceHistoryIcon'

import { generateKarnameUrl } from '../../../utils/redirectionManager'
import AffidavitIcon from '../../icons/AffidavitIcon'
import BuyIcon from '../../icons/BuyIcon'
import CarAdsIcon from '../../icons/CarAdsIcon'
import CarInspectionIcon from '../../icons/CarInspectionIcon'
import CarPriceIcon from '../../icons/CarPriceIcon'
import CarServicesIcon from '../../icons/CarServicesIcon'
import LeasingIcon from '../../icons/LeasingIcon'
import MenuIcon from '../../icons/MenuIcon'
import MotorInspectionIcon from '../../icons/MotorInspectionIcon'
import MyCarIcon from '../../icons/MyCarIcon'
import SellIcon from '../../icons/SellIcon'
import UsedCarIcon from '../../icons/UsedCarIcon'

const KARNAMEH_URL = import.meta.env.VITE_KARNAMEH_URL

export interface MenuListItemInterface {
  id: number
  title: string
  link?: string
  noMobile?: boolean
  icon: ({ color }: NavigationIconProps) => JSX.Element
  subMenu?: Array<Omit<MenuListItemInterface, 'subMenu'>>
}

type NavigationIconProps = {
  color?: string
  type?: 'navigation' | 'component'
  size?: string
}

export const karnamehServices: MenuListItemInterface[] = [
  {
    id: 11,
    title: 'خرید خودرو',
    icon: BuyIcon,
    link: generateKarnameUrl('/buy-used-cars'),
  },
  {
    id: 12,
    title: 'فروش خودرو',
    icon: SellIcon,
    link: generateKarnameUrl('/car-sell'),
  },
  {
    id: 13,
    title: 'کارشناسی خودرو',
    icon: CarInspectionIcon,
    link: generateKarnameUrl('/car-inspection'),
  },
  {
    id: 14,
    title: 'مبایعه نامه',
    icon: AffidavitIcon,
    link: generateKarnameUrl('/affidavit'),
  },
  {
    id: 15,
    title: 'خرید قسطی',
    link: generateKarnameUrl('/services/loan'),
    icon: LeasingIcon,
  },
  {
    id: 16,
    title: 'خرید گزارش قیمت',
    link: generateKarnameUrl('/services/trade-price-report/'),
    icon: PriceHistoryIcon,
  },
  {
    id: 17,
    title: 'معاوضه خودرو',
    link: generateKarnameUrl('/services/car-exchange'),
    icon: CarExchangeIcon,
  },
  // {
  //   id: 18,
  //   title: 'خلافی',
  //   icon: ViolationIcon,
  //   link: generateKarnameUrl('/services/car-violation'),
  // },
  {
    id: 19,
    title: 'سرویس دوره‌ای',
    icon: CarServicesIcon,
    link: generateKarnameUrl('/car-services'),
  },
  {
    id: 20,
    title: 'تخمین قیمت کارکرده',
    link: generateKarnameUrl('/car-price/used-car'),
    icon: UsedCarIcon,
  },
  {
    id: 21,
    title: 'کارشناسی موتور',
    icon: MotorInspectionIcon,
    link: generateKarnameUrl('/motor-inspection'),
  },
  // {
  //   id: 22,
  //   title: 'استعلام سوابق خودرو',
  //   icon: CarHistoryIcon,
  //   link: generateKarnameUrl('/car-authentication'),
  // },
]

export const carProfileServices = [
  {
    id: 1,
    title: 'فروش',
    icon: SellIcon,
    brandModelKey: 'car_model',
    link: generateKarnameUrl('/services/car-sell/order/select-car'),
  },
  // {
  //   id: 2,
  //   title: 'سرویس دوره‌ای',
  //   icon: CarServicesIcon,
  //   link: generateKarnameUrl('/services/car-services/car-info'),
  // },
  {
    id: 3,
    title: 'کارشناسی',
    icon: CarInspectionIcon,
    brandModelKey: 'car',
    link: generateKarnameUrl('/services/car-inspection/reserve-car-inspection'),
  },
  // {
  //   id: 4,
  //   title: 'استعلام سوابق',
  //   icon: CarHistoryIcon,
  //   link: generateKarnameUrl('/services/car-authentication/car-info'),
  // },
  {
    id: 5,
    title: 'معاوضه',
    icon: CarExchangeIcon,
    link: generateKarnameUrl('/services/car-exchange'),
  },
]

export const statics: StaticsInterface = {
  headerMenu: [
    {
      id: 1,
      title: 'خدمات',
      icon: MenuIcon,
      subMenu: karnamehServices,
      noMobile: true,
    },
    {
      id: 8,
      title: 'خودروی من',
      link: '/car-profile',
      icon: MyCarIcon,
      noMobile: true,
    },
    {
      id: 2,
      title: 'آگهی های خودرو',
      link: generateKarnameUrl('/buy-used-cars'),
      icon: CarAdsIcon,
      noMobile: true,
    },
    {
      id: 3,
      title: `قیمت روز`,
      link: generateKarnameUrl('/car-price'),
      icon: CarPriceIcon,
      noMobile: true,
    },
  ],
  profileMenu: [
    {
      id: '1',
      link: `${KARNAMEH_URL}/profile/inspection-list`,
      icon: CarInspectionIcon,
      title: 'سوابق درخواست کارشناسی خودرو',
    },
    {
      id: '2',
      link: `${KARNAMEH_URL}/profile/concierge-sale-list`,
      icon: SellIcon,
      title: 'سوابق درخواست دستیار معاملات',
    },
    {
      id: '3',
      link: `${KARNAMEH_URL}/profile/leasing-list`,
      icon: LeasingIcon,
      title: 'سوابق درخواست خرید اقساطی',
    },
    {
      id: '4',
      link: `${KARNAMEH_URL}/profile/price-report-list`,
      icon: PriceHistoryIcon,
      title: 'سوابق درخواست گزارش قیمت',
    },
  ],
}

interface StaticsInterface {
  headerMenu: Array<MenuListItemInterface>
  profileMenu: Array<ProfileMenuInterface>
}

export interface ProfileMenuInterface {
  id: string
  link: string
  icon: ({ color }: NavigationIconProps) => JSX.Element
  title: string
}
